import React, { useState, useContext  } from 'react'
import { connect } from 'react-redux'
import CustomModal from './CustomModal'
import Button from '../atoms/Button'
import Tag from '../atoms/Tag'

import { getPrice, sendDataLayer, avoid } from '../../utils/functions'
import { doloadRedeem } from '../../utils/helpers'
import { StateController } from './../../App'

function AwardBox({
  index,
  type,
  award,
  canRedem,
  termLink,
  termText,
  redemtionText,
  showStock,
  token,
  download,
  twoStepAuth
}) {
  const {
    setAwardSelected,
    handlerRootModal,
  } = useContext(StateController)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [showErrorDownload, setShowErrorDownload] = useState(false)
  const [showErrorRedemtion, setShowErrorRedemtion] = useState(false)

  const handlerModal = () => {
    let dateNow = Date.now().toString()
    let internalId = dateNow.substring(0, dateNow.length - 2)

    if (!avoid({internalId: internalId})) {
      console.log("Ejecutar con normalidad")
      sendDataLayer({datalayer: { event: "button_login", internalId: internalId.substring(0, internalId.length - 2) }, repeat: true})

      if (!download) {
        console.log("Interes de bono")
        sendDataLayer({datalayer: { event: "bond_interest", internalId: `${internalId.substring(0, internalId.length - 1)}72`, product_name: award?.name,product_price: award?.price,index: (index+1),},repeat: true})
      }
  
      if (download && !downloadLoading) {
        console.log("Redencion:", award)
        setDownloadLoading(true)
  
        doloadRedeem(award.id, token).then((data) => {
          console.log("result download:", data)
          setDownloadLoading(false)
          sendDataLayer({datalayer: {event: 'redownload', internalId: `${internalId.substring(0, internalId.length - 2)}55`, product_name: award?.name ,product_price: award?.price},repeat: true})
  
          if (data && (data.status === 402 || data.status === 400)) {
            setShowErrorDownload(true)
          }
        }).catch(error => {
          console.log("ERROR DOWNLOAD REDENTION:", error)
          setDownloadLoading(false)
          setShowErrorDownload(true)
        })
      } else {
  
        if (canRedem) {
          handlerRootModal()
          setAwardSelected({
            image: award.image,
            price: award.price,
            name: award.name,
            link: award.link,
            canRedem: canRedem,
            id: award.id,
            location: award.location,
            brand_id: award.idbrand,
            twoStepAuth: twoStepAuth,
            index: (index + 1),
            description: award.description,
          })
        }
      }

    } else {
      console.log("DETENBER!")
    }

  }

  return (
    <div className='mc-awards-container__items--item__container'>

      <CustomModal
        title="Error en la descarga"
        desc={'Ocurrió un error al obtener tu bono. Intentalo de nuevo más tarde.'}
        cta="Aceptar"
        image="image.png"
        show={showErrorDownload} setShowModal={setShowErrorDownload}
      />
      <CustomModal
        title="Error en el canje"
        desc={'Ocurrió un error al obtener tu bono. Intentalo de nuevo más tarde.'}
        cta="Aceptar"
        image="image.png"
        show={showErrorRedemtion} setShowModal={setShowErrorRedemtion}
      />

      {award.prepurchased && !download && <Tag text="Precompra" />}
      {showStock && <Tag position={"left"} text={`${award.stock} Disponible${award.stock > 1 ? 's' : ''}`} />}

      {type === 2 &&
          <>
            <div className="mc-awards-container__items--item__info">
              <div 
                className="mc-awards-container__items--item__image"
                style={{"backgroundImage": `url(/assets/images/${award.image})`}}
              ></div>
              <div className="mc-awards-container__items--item__info">
                <div className="mc-awards-container__items--item__name">{award.name}</div>
                <div className="mc-awards-container__items--item__price">USD {getPrice(award.price).replace('.','')}</div>
                <div className="mc-awards-container__items--item__description">
                  {award.link}
                </div>
              </div>
            </div>
            <Button
              type="primary"
              text={redemtionText}
              loading={false}
              status={`${canRedem ? 'active' : 'inactive'}`} 
              icon="inline-block.png"
              awardIcon="inline-award.png"
              onClick={(e) => handlerModal(e,)}
            />
            {termLink && <div className='mc-awards-container__items--item__terms' dangerouslySetInnerHTML={{ __html: termText }} />}
          </>
      }
    </div>
  )
}

const mapState = ({ user: { token } }) => {

  return {
    token
  }
}

export default connect(mapState)(AwardBox)