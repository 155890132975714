import React from 'react'

function CardBox({
  id,
  image,
  title,
  titleDesktop,
  desc,
  style,
  ext
}) {

  return (
    <div className={`mc-cardbox-container cardBoxStyle-${style}`}>
      {image &&
        <div className='mc-cardbox-container__image'>
          {style === 1 && <span>{id}.</span>}
          <img src={`/assets/images/${image}`} alt="Step image" />
        </div>
      }
      {ext
        ? <h2>
            <strong>{id}.</strong>
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </h2>
        : <h2>{title}</h2>
      }
      {titleDesktop &&
        <h4><div dangerouslySetInnerHTML={{ __html: titleDesktop }} /></h4>
      }
      {desc &&
        <div dangerouslySetInnerHTML={{ __html: desc }} />
      }
    </div>
  )
}

export default CardBox