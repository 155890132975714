import React from 'react'

const BackButton = () => {

    const handleClick = () => {
        window.history.back();
    }

    return (
        <div className="back-button" onClick={handleClick}>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M6.875 9.26033H6.84985L6.86763 9.24255L10.5387 5.57147C10.6867 5.42347 10.7604 5.25047 10.7604 5.052C10.7604 4.85363 10.6853 4.6758 10.5343 4.51816C10.3834 4.37388 10.2089 4.302 10.0104 4.302C9.81196 4.302 9.63881 4.37594 9.49052 4.5244L4.53662 9.48453C4.46244 9.5588 4.40841 9.63905 4.37427 9.72525C4.34006 9.81164 4.32292 9.90427 4.32292 10.0033C4.32292 10.1022 4.34006 10.1948 4.37427 10.2812C4.40844 10.3675 4.45972 10.4449 4.5282 10.5134L9.48653 15.4717C9.63749 15.6227 9.81313 15.6978 10.0139 15.6978C10.2146 15.6978 10.3879 15.6227 10.5342 15.4718L10.5342 15.4718L10.5344 15.4716C10.6853 15.3253 10.7604 15.1521 10.7604 14.9513C10.7604 14.7505 10.6863 14.576 10.5376 14.4273L6.86763 10.7573L6.84985 10.7395H6.875H15.2505C15.4603 10.7395 15.6356 10.669 15.7772 10.5282C15.9188 10.3873 15.9896 10.2129 15.9896 10.0043C15.9896 9.79569 15.9188 9.61979 15.7771 9.47598C15.6355 9.33221 15.4602 9.26033 15.2505 9.26033H6.875Z" fill="#005EB8" stroke="#005EB8" strokeWidth="0.0208333"/>
                </svg>
            </div>
            <div>
                <span>Regresar</span>
            </div>
        </div>
    )
}

export default BackButton