import React from 'react'
import Columns, { Col } from '../../layouts/Columns/Columns'
import { sendDataLayer } from '../../utils/functions'
import { Link } from 'react-router-dom'

function MajorAwardBanner({
  context
}) {

  const goBondDatalayer = (e) => {
    let dateNow = Date.now().toString()
    let internalId = dateNow.substring(0, dateNow.length - 4)

    sendDataLayer({datalayer: {
      event: 'go_bond',
      text: 'Quiero el premio mayor',
      internalId: `${internalId.substring(0, internalId.length - 2)}18`,
      link: '/premio-mayor'
    },repeat: true})

    // e.preventDefault()
    // alert("sdf")
  }

  return (
    <div className={`mc-major-award-banner`}>
      {context &&
        <Columns
          desktop={2}
          mobile={1}
          height={200}
        >
          <Col>
            <img src={`/assets/images/major-award-${context.user.segment.replace('Grupo ', '')}.png`} />
          </Col>
          <Col>
            <h2>
              {context && context.user.segment === 'Grupo 1' && 
                <></>
              }
              {context && context.user.segment === 'Grupo 2' && 
                <></>
              }
              {context && context.user.segment === 'Grupo 3' && 
                <></>
              }
              {context && context.user.segment === 'Grupo 4' && 
                <></>
              }
            </h2>
            <p>
              {context && context.user.segment === 'Grupo 1' && 
                <>Usa para todo tu Tarjeta de Débito Mastercard PacifiCard y podrás llevarte uno de los <span>5 bonos de Amazon por $100 USD.</span></>
              }
              {context && context.user.segment === 'Grupo 2' && 
                <>
                  Usa para todo tu Tarjeta de Débito Mastercard PacifiCard y podrás llevarte uno de estos 3 premios: < br/>< br/> <span>Un bono de $500 USD o un Apple Watch Serie 9 o un Galaxy Watch 7.</span>
                </>
              }
              {context && context.user.segment === 'Grupo 3' && 
                <>
                  Usa para todo tu Tarjeta de Débito Mastercard PacifiCard y podrás llevarte uno de estos 3 premios: < br/>< br/> <span>Un Iphone 15 Pro o un  Samsung S24 o un TV Samsung de 75”.</span>
                </>
              }
              {context && context.user.segment === 'Grupo 4' && 
                <>
                  Usa tu Tarjeta de Débito Mastercard PacifiCard para todo y podrás ganar uno de los <span>dos bonos de $2,000 USD para usar en Amazon, La Ganga o El Rosado.</span>
                </>
              }
            </p>
            <Link to="/premio-mayor" onClick={goBondDatalayer}>Quiero el premio mayor</Link>
          </Col>
        </Columns>
      }
    </div>
  )
}

export default MajorAwardBanner