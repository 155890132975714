import React, { useContext } from 'react'
import { StateController } from './../../App'

import Container from '../../layouts/Container/Container'
import ProgressBar from '../atoms/ProgressBar'
import GoalCounter from '../atoms/GoalCounter'
import Tabs from '../organisms/Tabs'
import { getProgressPercent, getPrice } from '../../utils/functions'
// import AlliancesGallery from '../organisms/AlliancesGallery'
// import Preloader from '../atoms/Preloader'
// import MajorAwardBanner from '../atoms/MajorAwardBanner'
import Columns, { Col } from '../../layouts/Columns/Columns'
import TestQuestions from '../organisms/TestQuestions'
import { sendDataLayer } from '../../utils/functions'
import SectionAllies from '../molecules/SectionAllies'

function Progress() {
  const { context } = useContext(StateController);

  const Goal = () => {

    return (
      <>

        <Columns
          desktop={2}
          mobile={1}
          height={200}
        >
          <Col>
            <div className='mc-tabs-container--content__body--title'>Así va tu meta en facturación:</div>
            <div className='mc-tabs-container--content__body--boxes'>
              <div className='mc-tabs-container--content__body--box'>
                <span>Llevas:</span>
                <div>{getPrice((context && context.tracing) ? context.tracing[`amount_1`] : 0)}</div>
              </div>
              <div className='mc-tabs-container--content__body--box'>
                <span>Te falta:</span>
                <div>{getPrice( ((context) ? context.user[`goal_amount_1`] : 0) - ((context && context.tracing) ? context.tracing[`amount_1`] : 0) )}</div>
              </div>
            </div>
            <div className='mc-tabs-container--content__body--progress'>
              <ProgressBar
                percent={getProgressPercent(context)}
                label={`${getProgressPercent(context)}%`}
                showPosition
              />
            </div>
          </Col>
          <Col>
            <img src="/assets/images/banner-progress.png" />
          </Col>
        </Columns>

        {context &&
          <div className='mc-tabs-container--content__body--cats'>
            <p>Recuerda que, para cumplir tu meta, debes realizar compras en <span>{context.user[`goal_category_1`]} comercios</span> de este tipo:</p>

            <div className='mc-tabs-container--content__body--cats__items'>
              {context.cats.map((cat, index) => {
                let idCat = cat.id
                return (
                  <div key={index} className={`mc-tabs-container--content__body--cats__items--item ${(context.tracing?.categories && context.tracing?.categories.includes(idCat)) ? 'active' : ''}`}>
                    <div>
                      {(context.tracing && context.tracing.categories && context.tracing.categories.includes(idCat)) && <img src="/assets/images/tick.png" />}
                      <div dangerouslySetInnerHTML={{ __html: cat.image }} />
                    </div>
                    <span>
                      {cat.name}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        }
      </>
    )
  }

  const Records = () => {

    return (
      <>
        <GoalCounter
          label={`Has cumplido tu meta`}
          rule={'complete'}
        />

        <img src="/assets/images/award-icon.png" />

        <p>Cada vez que alcances una nueva meta, < br />podrás participar por el premio especial.</p>
        <a href="/terminos-y-condiciones">*Aplican términos y condiciones.</a>

      </>
    )
  }

  const ctaDatalayer = () => {
    sendDataLayer({
      datalayer: {
        event: 'progress',
        text: 'Ver premios',
        link: '/premios'
      }, repeat: true
    })
  }

  return (
    <div className='mc-page progress inner slow_ani'>

      <Container>
        <h1>
          <span>Conoce tu meta</span> y sigue tu progreso
        </h1>

        <div className='mc-section-card'>

          <Tabs
            sections={[
              {
                title: "Meta y progreso",
                content: <Goal />
              },
              {
                title: "Tu récord de meta",
                content: <Records />
              }
            ]}
          />

          <div className='mc-section-card--last'>Última actualización de tus consumos: {(context && context.tracing) ? context.tracing.date_update : '00/00/0000'}</div>
          <div className='mc-section-card--copy'>Una vez cumplas tu meta podrás canjear tu premio</div>
          <div className='mc-section-card--cta' onClick={ctaDatalayer}>
            <a href="/premios">Ver premios</a>
          </div>

        </div>

        <SectionAllies />

        <div className='mc-section-testForm'>
          <TestQuestions context={context} />
        </div>

      </Container>
    </div>
  )
}
export default Progress