import React from 'react'
import { useNavigate } from 'react-router-dom'

function Button({
  type,
  text,
  loading,
  status,
  onClick,
  link,
  icon,
  awardIcon,
  redirect
}) {
  const navigate = useNavigate()
  const handler = () => {
    console.log("C", status, onClick() )

    if (status === 'active' && !loading) {
      link
        ? navigate(link)
        : onClick()
    }

    if (status && redirect) {
      window.location.href = redirect
    }
  }

  return (
    <div
      className={`mc-button-container mc-button-container__${type}`}
      onClick={handler}
    >
      <button className={`slow_ani mc-button-container__status--${status}`}>
        {loading
          ? <img src='/assets/images/preloader_1.gif' alt='loader' className='inline-loader' />
          : <>
              {(status === 'inactive' && icon) ? <img src={`/assets/images/${icon}`} className='inline-block' /> : ''}
              {(status === 'active' && awardIcon) ? <img src={`/assets/images/${awardIcon}`} className='inline-block' /> : ''}
              {text}
            </>
        }
      </button>
    </div>
  )
}

export default Button