import React, { useEffect, useContext } from 'react'
import { StateController } from './../../App'

import Container from '../../layouts/Container/Container'

function MajorAward() {
  const { context } = useContext(StateController);

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page major-award inner slow_ani'>

      <div className='mc-major-award-t1'>
        <Container>
          <div className='mc-major-award-window'>
            <div className={`mc-major-award-t1-left layout-${context?.user?.segment.replace('Grupo ', '')}`}>
              <div></div>
              <div>
                {context && context.user.segment === 'Grupo 1' && 
                  <>Sigue usando tu Tarjeta de Débito Mastercard PacifiCard para todo y podrás llevarte uno de los <span>5 bonos de Amazon por $100 USD.</span></>
                } 
                {context && context.user.segment === 'Grupo 2' && 
                  <>Sigue usando tu Tarjeta de Débito Mastercard PacifiCard para todo y podrás llevarte uno de estos 3 premios: < br/>< br/><span>Un bono de $500 USD o un Apple Watch Serie 9 o un Galaxy Watch 7.</span></>
                }
                {context && context.user.segment === 'Grupo 3' && 
                  <>Sigue usando tu Tarjeta de Débito Mastercard PacifiCard para todo y podrás llevarte uno de estos 3 premios: < br/>< br/><span>Un Iphone 15 Pro o un  Samsung S24 o un TV Samsung de 75{'"'}.</span></>
                }
                {context && context.user.segment === 'Grupo 4' && 
                  <>Sigue usando tu Tarjeta de Débito Mastercard PacifiCard para todo y podrás ganar uno de <span>los 2 bonos de $2,000 USD para usar en Amazon, La Ganga o El Rosado.</span></>
                }
              </div>
            </div>
            {context && 
              <div className='mc-major-award-t1-right'>
                <img src={`/assets/images/major-award-${context.user.segment.replace('Grupo ', '')}.png`} />
              </div>
            }

          </div>
        </Container>
      </div>
      
      <div className='mc-major-award-t2'>
        <div>Conoce más detalles</div>
        {context && context.user.segment === 'Grupo 1' && 
          <div>
            <p><strong>¿Cómo ganar?</strong></p>
            <p><strong>Para ser elegible como ganador, el participante debe cumplir con los siguientes requisitos:</strong></p>
            <ul style={{listStyle:'none'}}>
              <li>1. Ingresar a esta plataforma con el código único de acceso.</li>
              <li>2. Haber canjeado un bono una vez cumplido su primera meta.</li>
            </ul>
            <p>
             <strong>• Premios:</strong> Cinco (5) ganadores de bonos de $100 USD en Amazon, distribuidos de la siguiente manera: Tres (3) premios para los clientes que hayan alcanzado la meta en más ocasiones durante la vigencia de la campaña. Dos (2) premios para los clientes que acumulen el mayor valor en facturación al finalizar la vigencia de la campaña. Siempre que hayan cumplido los dos requisitos mencionados previamente.
            </p>
            <p>Tu bono se añadirá automáticamente a tu saldo en Amazon. Las compras que hagas se descontarán de ese saldo, y cualquier cantidad que no uses quedará disponible en tu cuenta. Si el total de tu compra supera el saldo, puedes cubrir la diferencia con otro método de pago.</p>
            <p><strong>• Descripción:</strong> Gift Card para compras en pagina de Amazon USA</p>
            <p><strong>• Cobertura: Compra Online USA</strong></p>
            <p><strong>• Términos y condiciones del bono:</strong> Las Tarjetas de Regalo de Amazon.com para Estados Unidos de America, que se agregan automáticamente a su Saldo de Amazon.com. Para ver su Saldo de Amazon.com, visite Su Cuenta. Las compras son deducidas de su Saldo de Amazon.com. Cualquier Saldo de Amazon.com no usado permanecerá asociado con su cuenta de Amazon.com. Si una compra excede su Saldo de Amazon.com, el monto pendiente debe ser pagado con otro método de pago. <a style={{lineBreak:'anywhere'}} href="https://www.amazon.com/-/es/gp/help/customer/display.html?nodeId=201936990" target="_blank" rel="noopener noreferrer">https://www.amazon.com/-/es/gp/help/customer/display.html?nodeId=201936990</a>. </p>
            <p><strong>• Forma de redención:</strong> Virtual en amazon.com</p>
            <p><strong>• Proceso de Canje:</strong></p>
            <ul style={{listStyle:'none'}}>
              <li><strong>1. </strong>Visita amazon.com. </li>
              <li><strong>2. </strong>Regístrate o inicia sesión. </li>
              <li><strong>3. </strong>En el momento del pago. Ingresa el código y selecciona el botón Canjear. </li>
              <li><strong>4. </strong>Finaliza la compra.</li>              
            </ul>
            <p><strong>• Vigencia:</strong> 6 meses</p>
            <p>Se premiarán cinco (5) ganadores.</p>
            <p>Para más información, consulta los <a href="/terminos-y-condiciones">términos y condiciones.</a></p>
          </div>
        }
        {context && context.user.segment === 'Grupo 2' && 
          <div>
            <p><strong>¿Cómo ganar?</strong></p>
            <p><strong>Para ser elegible como ganador, el participante debe cumplir con los siguientes requisitos:</strong></p>
            <ul style={{listStyle:'none'}}>
              <li>1. Ingresar a esta plataforma con el código único de acceso.</li>
              <li>2. Haber canjeado un bono una vez cumplido su primera meta.</li>
            </ul>
            <p>
             <strong>• Premios:</strong> Dos (2) ganadores, cada uno de los cuales recibirá un (1) premio que podrá elegir entre las siguientes opciones: Bono por $500 USD de Amazon, Apple Watch o un Galaxy Watch. Los cuales se entregarán de esta forma:  Un (1) premio para el cliente que haya alcanzado su meta en más ocasiones durante la vigencia de la campaña. Un (1) premio para el cliente que haya acumulado el mayor valor en facturación al finalizar la vigencia de la campaña. Siempre que hayan cumplido los dos requisitos mencionados previamente.
            </p>
            <p><strong>Te contamos las características de todos los premios para que elijas el que más guste.</strong></p>
            <p>
              <div><strong>Bono de Amazon por $500 USD</strong></div>
              <ul>
                <li>Tu bono se añadirá automáticamente a tu saldo en Amazon.</li>
                <li>Las compras que hagas se descontarán de ese saldo, y cualquier cantidad que no uses quedará disponible en tu cuenta.</li>
                <li>Si el total de tu compra supera el saldo, puedes cubrir la diferencia con otro método de pago.</li>
              </ul>
            </p>
            <p><strong>• Descripción:</strong> Gift Card para compras en pagina de Amazon USA</p>
            <p><strong>• Cobertura: Compra Online USA</strong></p>
            <p><strong>• Términos y condiciones del bono:</strong> Las Tarjetas de Regalo de Amazon.com para Estados Unidos de America, que se agregan automáticamente a su Saldo de Amazon.com. Para ver su Saldo de Amazon.com, visite Su Cuenta. Las compras son deducidas de su Saldo de Amazon.com. Cualquier Saldo de Amazon.com no usado permanecerá asociado con su cuenta de Amazon.com. Si una compra excede su Saldo de Amazon.com, el monto pendiente debe ser pagado con otro método de pago. <a style={{lineBreak:'anywhere'}} href="https://www.amazon.com/-/es/gp/help/customer/display.html?nodeId=201936990" target="_blank" rel="noopener noreferrer">https://www.amazon.com/-/es/gp/help/customer/display.html?nodeId=201936990</a>. </p>
            <p><strong>• Forma de redención:</strong> Virtual en amazon.com</p>
            <p><strong>• Proceso de Canje:</strong></p>
            <ul style={{listStyle:'none'}}>
              <li><strong>1. </strong>Visita amazon.com. </li>
              <li><strong>2. </strong>Regístrate o inicia sesión. </li>
              <li><strong>3. </strong>En el momento del pago. Ingresa el código y selecciona el botón Canjear. </li>
              <li><strong>4. </strong>Finaliza la compra.</li>              
            </ul>
            <p><strong>• Vigencia:</strong> 6 meses</p>
            <p>
              <div><strong>Apple Watch Series 9 GPS de 45 mm</strong></div>
              <ul>
                <li>Gestos: junta el dedo índice y pulgar para realizar acciones.</li>
                <li>Brillo 2.000 Nits: dos veces más brillo que el Apple Watch Series 8.</li>
                <li>Rastreo Preciso indica qué distancia se encuentra tu iPhone.</li>
                <li>El Apple Watch es nuestro primer producto neutro en carbono.</li>
                <li>Con la app de ECG puedes generar un electrocardiograma.</li>
              </ul>
            </p>
            <p>
              <div><strong>Samsung Galaxy Watch 7 44 mm</strong></div>
              <ul>
                <li>Respuestas personalizadas a tus mensajes mediante Galaxy AI .</li>
                <li>BioActive sensor mejorado con la más alta precisión.</li>
                <li>Encuentra tu teléfono desde el Watch 7 con Samsung Find.</li>
                <li>Diseño moderno con materiales de alta resistencia.</li>
              </ul>
            </p>
            <p>Se premiarán dos (2) ganadores.</p>
            <p>Para más información, consulta los <a href="/terminos-y-condiciones">términos y condiciones.</a></p>
            {/* <p>
              <div><strong>SAMSUNG Galaxy Watch 7 44 mm</strong></div>
              <ul>
                <li>Respuestas personalizadas a tus mensajes mediante Galaxy AI.</li>
                <li>BioActive sensor mejorado con la más alta precisión.</li>
                <li>Encuentra tu teléfono desde el Watch 7 con Samsung Find.</li>
                <li>Diseño moderno con materiales de alta resistencia.</li>
              </ul>
            </p> */}
          </div>
        }
        {context && context.user.segment === 'Grupo 3' && 
          <div>

            <p><strong>¿Cómo ganar?</strong></p>
            <p><strong>Para ser elegible como ganador, el participante debe cumplir con los siguientes requisitos:</strong></p>
            <ul style={{listStyle:'none'}}>
              <li>1. Ingresar a esta plataforma con el código único de acceso.</li>
              <li>2. Haber canjeado un bono una vez cumplido su primera meta.</li>
            </ul>
            <p>
             <strong>• Premios:</strong> Dos (2) ganadores, cada uno de los cuales recibirá un (1) premio que podrá elegir entre las siguientes opciones:  iPhone 15 Pro, Celular Samsung S24 Plus o TV Samsung de {'75"'}. Los cuales se entregarán de esta forma:  Un (1) premio para el cliente que haya alcanzado su meta en más ocasiones durante la vigencia de la campaña. Un (1) premio para el cliente que haya acumulado el mayor valor en facturación al finalizar la vigencia de la campaña. Siempre que hayan cumplido los dos requisitos mencionados previamente
            </p>
            <p><strong>Te contamos las características de todos los premios para que elijas el que más guste.</strong></p>
            <p>
              <div><strong>IPhone 15 Pro</strong></div>
              <ul>
                <li>Capacidad de almacenamiento: 256GB.</li>
                <li>Conectividad: 5G.</li>
                <li>Marca y modelo del procesador: Chip A17 Pro.</li>
                <li>Sistema operativo: IOS 17.</li>
                <li>Memoria RAM: 8GB.</li>
              </ul>
            </p>
            <p>
              <div><strong>Samsung S24 Plus</strong></div>
              <ul>
                <li>Capacidad de almacenamiento: 256GB.</li>
                <li>Conectividad: 5G.</li>
                <li>Marca y modelo del procesador: Exynos 2400.</li>
                <li>Sistema operativo: Android 14.</li>
                <li>Memoria RAM: 12GB.</li>
              </ul>
            </p>
            <p>
              <div><strong>TV Samsung 75{'"'} Pulgadas</strong></div>
              <ul>
                <li>Disfruta una amplia gama de colores vivos y vibrantes.</li>
                <li>Sonido Envolvente: disfruta el sonido según la escena.</li>
                <li>Motion Xcelerator: Movimiento fluido y claridad mejorada.</li>
                <li>Siente la realidad de la resolución 4K UHD.</li>
              </ul>
            </p>
            <p>Se premiarán dos (2) ganadores.</p>
            <p>Para más información, consulta los <a href="/terminos-y-condiciones">términos y condiciones.</a></p>
          </div>
        }
        {context && context.user.segment === 'Grupo 4' && 
          <div>
            <p><strong>¿Cómo ganar?</strong></p>
            <p><strong>Para ser elegible como ganador, el participante debe cumplir con los siguientes requisitos:</strong></p>
            <ul style={{listStyle:'none'}}>
              <li>1. Ingresar a esta plataforma con el código único de acceso.</li>
              <li>2. Haber canjeado un bono una vez cumplido su primera meta.</li>
            </ul>
            <p>
             <strong>• Premios:</strong> Dos (2) ganadores, cada uno de los cuales recibirá un (1) bono de $2.000 USD que podrá elegir entre las siguientes opciones:  Bono de Amazon, Bono Grupo El Rosado o Bono La Ganga. Los cuales se entregarán de esta forma: Un (1) premio para el cliente que haya alcanzado su meta en más ocasiones durante la vigencia de la campaña. Un (1) premio para el cliente que haya acumulado el mayor valor en facturación al finalizar la vigencia de la campaña. Siempre que hayan cumplido los dos requisitos mencionados previamente.
            </p>
            <p>
              <div><strong>Amazon</strong></div>
              <div>Tu bono se añadirá automáticamente a tu saldo en Amazon. Las compras que hagas se descontarán de ese saldo, y cualquier cantidad que no uses quedará disponible en tu cuenta. Si el total de tu compra supera el saldo, puedes cubrir la diferencia con otro método de pago.</div>
            </p>

            <p><strong>• Descripción:</strong> Gift Card para compras en pagina de Amazon USA</p>
            <p><strong>• Cobertura: Compra Online USA</strong></p>
            <p><strong>• Términos y condiciones del bono:</strong> Las Tarjetas de Regalo de Amazon.com para Estados Unidos de America, que se agregan automáticamente a su Saldo de Amazon.com. Para ver su Saldo de Amazon.com, visite Su Cuenta. Las compras son deducidas de su Saldo de Amazon.com. Cualquier Saldo de Amazon.com no usado permanecerá asociado con su cuenta de Amazon.com. Si una compra excede su Saldo de Amazon.com, el monto pendiente debe ser pagado con otro método de pago. <a style={{lineBreak:'anywhere'}} href="https://www.amazon.com/-/es/gp/help/customer/display.html?nodeId=201936990" target="_blank" rel="noopener noreferrer">https://www.amazon.com/-/es/gp/help/customer/display.html?nodeId=201936990</a>. </p>
            <p><strong>• Forma de redención:</strong> Virtual en amazon.com</p>
            <p><strong>• Proceso de Canje:</strong></p>
            <ul style={{listStyle:'none'}}>
              <li><strong>1. </strong>Visita amazon.com. </li>
              <li><strong>2. </strong>Regístrate o inicia sesión. </li>
              <li><strong>3. </strong>En el momento del pago. Ingresa el código y selecciona el botón Canjear. </li>
              <li><strong>4. </strong>Finaliza la compra.</li>              
            </ul>
            <p><strong>• Vigencia:</strong> 6 meses</p>

            <p>
              <div><strong><div><strong>La Ganga</strong></div></strong></div>
              <div>
                <ul>
                  <li><strong>•</strong>Se pueden comprar productos cuyos valores sean superiores al monto entregado, ajustando la diferencia. </li>
                  <li><strong>•</strong>La gift card puede ser utilizada para compras al contado o cubrir la cuota inicial de un nuevo crédito, pero no podrá ser utilizada para el pago de cuotas de créditos existentes.</li>
                  <li><strong>•</strong>Una vez emitida tu gift card no se pueden realizar cambios ni devoluciones.</li>
                  <li><strong>•</strong>Tienes hasta la fecha indicada para disfrutar de tu gift card. Después de esta fecha, ya no estará disponible para su uso.</li>
                  <li><strong>•</strong>antén tu gift card segura, ya que no se puede reemplazar en caso de pérdida o robo.</li>
                </ul>
              </div>
            </p>
            <p><strong>• Descripción: </strong>Almacenes La Ganga es la cadena más grande del país, comercializando las mejores marcas en electrodomésticos, artículos para el Hogar y venta de motocicletas. Fundada en Guayaquil, Ecuador, en 1983. Actualmente cuenta con más de 200 tiendas a nivel nacional. Somos la primera opción de compra en la venta de electrodomésticos.</p>
            <p><strong>• Cobertura: </strong> Quito, Sangolqui, Ambato, Atacames, Baños, Atuntaqui, Bahia de Caraquez, Baños de Ambato, Cayambe, Chone, Cotacachi, El Angel, El Carmen, El Chaco, El Coca, Esmeraldas, Imbabura, Guaranda, Ibarra, La Concordia, La Mana, Lago Agrio, Latacunga, Macas, Machachi, Otavalo, Pilar, Pedernales, Puyo, Quininde, Riobamba, San Lorenzo, San vicente, Santo Domingo, Tena, Tulcan, El Carmen, San Gabriel, Guayaquil, Pascuales, Babahoyo, Balzar, Bucay. Buena Fe, Calceta, Caluma, Cañar, Colimen, Cuenca, Daule, Duran, El Empalme, El Guabo, El Triunfo, Machala, Duran, La Libertad, Naranjito, El empalme, Manta, Milagro, Quevedo, Huaquillas, Jipijapa, Naranjal, Naranjito, Pasaje, Pedro Carbo, Piñas, Playas, Posorja, Quinsaloma, Ricaurte, Salitre, Samborondon, San Juan, Santa Lucia, Santa Rosa, Simon Bolivar, Valencia, Ventanas, Vinces, Babahoyo, Daule, El Triunfo, </p>
            <p><strong>• Términos y condiciones del bono:</strong> Se puede comprar productos cuyos valores sean superiores al monto entregado, ajustando la diferencia. </p>

            <ul style={{listStyle:'none'}}>
              <li><strong>1. </strong> La gift card puede ser utilizada para compras al contado o cubrir la cuota inicial de un nuevo crédito, pero no podrá ser utilizada para el pago de cuotas de créditos existentes.</li>
              <li><strong>2. </strong>  Una vez emitida tu gift card no se pueden realizar cambios ni devoluciones.</li>
              <li><strong>3. </strong> Tienes hasta la fecha indicada para disfrutar de tu gift card. Después de esta fecha, ya no estará disponible para su uso.</li>
              <li><strong>4. </strong> Mantén tu gift card segura, ya que no se puede reemplazar en caso de pérdida o robo.S5</li>              
            </ul>
            <p><strong>• Forma de redención:</strong> Tienda física</p>
            <ul style={{listStyle:'none'}}>
              <li><strong>1. </strong>El beneficiario se acerca al local de su preferencia. </li>
              <li><strong>2. </strong>En caja muestra la gift card digital con el código. </li>
              <li><strong>3. </strong>Verifica el cajero la información de la gift card.</li>
              <li><strong>4. </strong>Procede a la compra.</li>              
            </ul>
            <p><strong>• Vigencia:</strong> 6 meses</p>


            <p>
              <div><strong>El rosado</strong></div>
              <div>Permite acceder a la cadena de establecimientos afiliados a Corporación El Rosado.</div>
            </p>
            <p><strong>• Descripción: </strong>Gift Card El Rosado, permite acceder a la cadena de establecimiento afiliados a Corporación el Rosado.</p>
            <p><strong>• Cobertura: </strong>Guayaquil, Quito, Machala, Riobamba, Manta, Portoviejo, Quevedo, Santo Domingo, La Libertad, Milagro.</p>
            <p><strong>• Términos y condiciones del bono:</strong> </p>
            <p><strong>-Validez:</strong> Antes de usar la gift card, verifica su fecha de vencimiento. Una vez expirado el plazo de validez, la gift card ya no podrá ser utilizada ni reemplazada.</p>
            <p> <strong>- Uso:</strong> El valor de la gift card será acreditado en la APP BIVI, que puede ser utilizado en todos los comercios de Corporación El Rosado. El valor acreditado en la APP BIVI es un método de pago en el comercio.</p>
            <p><strong>- No reembolsable:</strong> La gift card no admite cambios ni devoluciones después de su emisión.</p>
            <p><strong>- Pérdida o robo:</strong> La responsabilidad de la gift card recae en el beneficiario. En caso de pérdida o robo, lamentablemente, no puede ser reemplazada.</p>
            <p><strong>- Tiempo de gestión:</strong> La gestión de la gift card inicia en el momento en que GiftPoint cuenta con toda la información requerida. A partir de ese momento, el proceso toma 4 días laborables</p>
            <p><strong>• Forma de redención:</strong> Tienda física</p>
            <p><strong>• Proceso de Canje:</strong></p>
            <ul style={{listStyle:'none'}}>
              <li><strong>1. </strong>Contáctanos: Celular / Whatsapp:+593 987861569. Correo electrónico: info@giftpoint.com.ec </li>
              <li><strong>2. </strong>Indícanos tu código de canje. En un rango de 4 días laborables recibirás un código a tu celular para activar tu tarjeta digital.</li>
              <li><strong>3. </strong>Una vez recibido el código, descarga de manera gratuita la App BiVi desde el App Store: <a href="https://apple.co/3tMIiT0" target="_blank" rel="noopener noreferrer">https://apple.co/3tMIiT0</a> o Play Store: <a href="https://bit.ly/31gLSbT" target="_blank" rel="noopener noreferrer">https://bit.ly/31gLSbT</a></li>
              <li><strong>4. </strong>Finaliza la compra.</li>              
            </ul>

            <p><strong>Para utilizarla sigue estos pasos:</strong></p>
            <ul style={{listStyle:'none'}}>
              <li><strong>•</strong> Regístrate con tu número de cédula y celular.</li>
              <li><strong>•</strong> Ingresa a tu cuenta</li>
              <li><strong>•</strong> Desliza hacia la derecha a la sección de {'""Tarjetas de Regalo""'} </li>
              <li><strong>•</strong> Haz click en el botón inferior “Mis Tarjetas”, donde podrás visualizar tu Tarjeta de Regalo.</li>              
              <li><strong>•</strong> Haz click en el botón “Usar” y genera el código que debes indicar en la caja de los locales al pagar.  *Puedes hacer uso de tu saldo en cualquier local de la filial El Rosado.</li>              
            </ul>

            <p><strong>• Vigencia:</strong> 6 meses</p>
            
            <p>Para más información, consulta los <a href="/terminos-y-condiciones">términos y condiciones.</a></p>
          </div>
        }

      </div>

      
    </div>
  )
}
export default MajorAward