import React, { useContext } from 'react'
import { StateController } from './../../App'

import Container from '../../layouts/Container/Container'
import SliderRotator from '../organisms/SliderRotator'
import TestQuestions from '../organisms/TestQuestions'
import MajorAwardBanner from '../atoms/MajorAwardBanner'

function Mechanic() {
  const { context } = useContext(StateController);

  return (
    <div className='mc-page mechanic inner slow_ani'>
      
      <Container>
        <h1>¿Cómo ganar?</h1>
        <h2>Sigue estos pasos y déjale el resto a tu Tarjeta de Débito Mastercard PacifiCard:</h2>

        <div className='mc-section-login-slider'>
          <SliderRotator
            mobileItemsToShow={1}
            desktopItemsToShow={3}
            style={1}
            ext={true}
            list={
              [
                {
                  id: 1,
                  image: "mechanic_slide_1.png",
                  title: "<span>Regístrate con el código</span> que te enviamos por correo o mensaje de texto.",
                  titleDesktop: "<span>Regístrate con el código</span> que te enviamos por correo o mensaje de texto."
                },
                {
                  id: 2,
                  image: "mechanic_slide_2.png",
                  title: 'Usa tu Tarjeta de Débito Mastercard PacifiCard para pagar "TODO" entre el <span>2 de septiembre y el 27 de octubre de 2024.</span>',
                  titleDesktop: 'Usa tu Tarjeta de Débito Mastercard PacifiCard para pagar "TODO" entre el <span>2 de septiembre y el 27 de octubre de 2024.</span>',
                },
                {
                  id: 3,
                  image: "mechanic_slide_3.png",
                  title: 'Consulta tu meta y los comercios que te ayudarán a alcanzarla en la sección <span>"Meta y progreso".</span>',
                  titleDesktop: 'Consulta tu meta y los comercios que te ayudarán a alcanzarla en la sección <span>"Meta y progreso".</span>'
                },
                {
                  id: 4,
                  image: "mechanic_slide_4.png",
                  title: "<span>Cumple tu meta, canjea tu bono</span> y participa por un premio especial al final de la campaña.",
                  titleDesktop: "<span>Cumple tu meta, canjea tu bono</span> y participa por un premio especial al final de la campaña."
                },
              ]
            }
          />
        </div>

        <MajorAwardBanner context={context} />

        <div className='mc-section-testForm'>
          <TestQuestions context={context} />
        </div>
      </Container>
      
    </div>
  )
}
export default Mechanic