import { URL, GLOBAL_CODE, APIKEY } from "../constants/constants"

export const canRedeem = async ({ token }) => {
  const res = await fetch(`${URL}canRedeem`, {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    },
  }).catch(err => console.log("Hubo un error: " + err))

  if (res) {
    return (res.json())
  }
}

export const redeem = async ({ token, price, id_award, quantumName, quantumDoc, quantum_site}) => {
  try {
    const res = await fetch(`${URL}/redemptions`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        'Content-Type': 'application/json',
        "x-api-key": APIKEY,
        'x-access-token': token,
        'x-access-campaign': GLOBAL_CODE
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        "redemtion": {
          "award_id": id_award,
          "value": price,
          "quantum_name": quantumName,
          "quantum_document": quantumDoc,
          "quantum_site": quantum_site
        },
        "type": "redeem"
      })
    })

    return res.json()

  } catch (error) {
    return error
  }
}

export const downloadRedeem = async ({ token, id }) => {
  try {
    const res = await fetch(`${URL}/redemptions`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        'Content-Type': 'application/json',
        "x-api-key": APIKEY,
        'x-access-token': token,
        'x-access-campaign': GLOBAL_CODE
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        "redemtion": {
          "id": id
        },
        "type": "download"
      })
    })

    return res.json()

  } catch (error) {
    return error
  }
}

export const getRedeemeds = async ({ token }) => {
  const res = await fetch(`${URL}redemptions`, {
      headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
      },
  });
  if (res) {
      return (res.json())
  }
}

export const getDepartments = async ({ token, brand_id }) => {
  try {
    const res = await fetch(`${URL}/redemptions`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        'Content-Type': 'application/json',
        "x-api-key": APIKEY,
        'x-access-token': token,
        'x-access-campaign': GLOBAL_CODE
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        "redemtion": {
          "brand_id": brand_id,
        },
        "type": "quantumDept"
      })
    })

    return res.json()

  } catch (error) {
    return error
  }
}

export const getCities = async ({ token, brand_id, department_id }) => {
  try {
    const res = await fetch(`${URL}/redemptions`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        'Content-Type': 'application/json',
        "x-api-key": APIKEY,
        'x-access-token': token,
        'x-access-campaign': GLOBAL_CODE
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        "redemtion": {
          "brand_id": brand_id,
          "department_id": department_id
        },
        "type": "quantumCity"
      })
    })

    return res.json()

  } catch (error) {
    return error
  }
}

export const getSites = async ({ token, brand_id, department_id, city_id }) => {
  try {
    const res = await fetch(`${URL}/redemptions`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        'Content-Type': 'application/json',
        "x-api-key": APIKEY,
        'x-access-token': token,
        'x-access-campaign': GLOBAL_CODE
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        "redemtion": {
          "brand_id": brand_id,
          "department_id": department_id,
          "city_id": city_id,
        },
        "type": "quantumSite"
      })
    })

    return res.json()

  } catch (error) {
    return error
  }
}
