import React from 'react'
import Slider from "react-slick"

import CardBox from '../molecules/CardBox'
import useWidth from '../../hooks/useWidth'

function SliderRotator({
  mobileItemsToShow,
  desktopItemsToShow,
  list,
  style,
  showArrows,
  ext,
  infinite
}) {
  const { width } = useWidth()

  const settings = {
    dots: true,
    infinite: infinite ? true : false,
    speed: 500,
    slidesToShow: desktopItemsToShow,
    slidesToScroll: 1,
    autoplay: infinite ? true : false,
    arrows: showArrows,
    centerPadding: '20px',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: mobileItemsToShow,
          slidesToScroll: 1,
          infinite: infinite ? true : false,
          dots: true
        }
      }
    ]
  }

  return (
    <div className={`mc-slider-rotator`}>
      <Slider {...settings}>
        {list.filter((slide) => {

          if (width < 992) {
            if (slide.space) {
              return false
            } else {
              return true
            }
          } else {
            return true
          }
          
        }).map((slide, index) => {

          return (
            <div key={index} className={`mc-slider-rotator__slide ${slide.space ? 'free' : ''}`}>
              <CardBox
                id={slide.id}
                image={slide.image}
                title={slide.title}
                titleDesktop={slide.titleDesktop}
                desc={slide.desc}
                style={style}
                ext={ext}
              />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default SliderRotator